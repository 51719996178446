import React, { Component } from 'react';
import { TagColumn } from './TagColumn';

export class TopTags extends Component {

	render(){
		return(
			<div className="additional-content major-section">
				<div className="grid-container">
					<div className="grid-x">
						<h2 className="text-center">Top 30 Tags</h2>
					</div>
					<div className="grid-x">
						{this.props.tags.map((tags, i) => {
							return (
								<TagColumn tags={tags} key={i} />
							)    
				        })}
					</div>
				</div>
			</div>
		)

	}


}