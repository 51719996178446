import axios from 'axios';
const baseURL = "https://api.jesse.photo/api/";


const getTodaysPhoto = () =>{
    const request = axios.get(`${baseURL}date/`);
    return request.then(response => response.data);
}

const getRandomPhoto = () => {
    const request = axios.get(`${baseURL}date/?random`);
    return request.then(response => response.data);
}

const getPhotoByDay = (day) =>{
    const request = axios.get(`${baseURL}date/?date=${day}`);
    return request.then(response => response.data);
}

const getHistoryForDay = (day) =>{
    const request = axios.get(`${baseURL}date/history/?date=${day}`);
    return request.then(response => response.data);
}

export default { getTodaysPhoto, getRandomPhoto, getPhotoByDay, getHistoryForDay }