import React from 'react';
import PhotoThumbnail from '../../shared/PhotoThumbnail';

const Day = ({day, thumbnail, date}) => {
	if (day === 0){
		return(
			<div className="thumbnail expire"></div>
		)
	}
	else if (thumbnail){
		return(
			<PhotoThumbnail
				url={`/${date}`}
				image={thumbnail}
				text={day}
			/>
		)
	}else{
		return(
			<div className="thumbnail empty">
				<span>{day}</span>
			</div>
		)
	}
}
export default Day;