import React from 'react';
import Tag from './Tag';

const TagLoop = ({tags}) => {
	return (
		<ul className="tags menu">

			{tags.map((singleTag, i) => {
				return(
					<Tag tagName={singleTag.name}
						 slug={singleTag.slug}
						 key={singleTag.id} />
				) 
			})}

		</ul>
	)
}

export default TagLoop;