import React from 'react';
import { Tag } from '../topTags/Tag';

const SearchBar = ({searchTerm, handleChange, handlePress, searchResults, currentIndex}) =>{
	return(
		<div className="major-section suggestionsBox">
			<input type="text"
				value={searchTerm}
				onChange={handleChange}
				onKeyUp={handlePress} />

			<ul className="menu vertical suggestionList">
				{searchResults.map((result, i) => {
					return(
						<Tag tagName={result.name}
							 slug={result.slug} 
							 key={result.id}
							 active={(i+1 === currentIndex) ? true : false} />
					);
				})}
			</ul>
		</div>
	)
}

export default SearchBar;