import React from 'react';
import { Link } from 'react-router-dom';

const VideoSvg = ({count}) =>{
    return (
        <Link to="/tag/video">
            <svg version="1.1" id="videos" x="0px" y="0px" viewBox="0 0 300 300">
                <g>
                    <path className="st0" d="M72.3,106.8c2,0,4.1,0,6.1,0c61.1,0,122.1,0,183.2,0c7.7,0,8,0.3,8,8c0,45.8,0,91.5,0,137.3
                        c0,22.5-13.7,36.7-36.1,36.8c-54.6,0.2-109.2,0.2-163.8,0c-20.1-0.1-34.2-14.7-34.2-34.9c0-45,0.1-90-0.2-134.9
                        c-0.1-7.3-1.7-14.7-3.4-21.9c-5.2-21.5,4.4-38.4,25.8-44C111.1,39.3,164.5,25.7,217.9,12c16-4.1,33.3,5.3,38.8,21
                        c1.8,5.2,3.1,10.5,4.5,15.8c1.8,6.9,1.5,7.6-5.4,9.4c-26.3,6.8-52.6,13.5-78.9,20.3c-33.3,8.6-66.6,17.2-99.9,25.8
                        c-1.6,0.4-3.2,1-4.8,1.6C72.2,106.1,72.2,106.4,72.3,106.8z M42.6,160.8c0,32.3-0.5,63.9,0.2,95.5c0.3,15.3,11.5,25,26.5,25
                        c55.4,0.1,110.7,0.1,166.1,0c15.7,0,26.2-10.4,26.5-26.1c0.3-21.6,0.2-43.2,0.2-64.8c0-9.8,0-19.5,0-29.6
                        C188.8,160.8,116.1,160.8,42.6,160.8z M42.9,114.1c0,13.1,0,25.7,0,38.7c18.1,0,35.8,0.1,53.4-0.1c1.4,0,3.4-1.7,4.1-3.2
                        c3.5-6.8,6.8-13.7,10-20.6c2.2-4.7,4.3-9.4,6.8-14.8C92.1,114.1,67.9,114.1,42.9,114.1z M114.2,87.1c-9.3-11.3-17.9-21.7-26.6-31.9
                        c-0.8-0.9-2.9-1.5-4.1-1.2c-9.9,2.5-19.8,4.8-29.5,7.9c-7.9,2.6-12.8,8.5-15.5,16.5c-3.2,9.5,1,18,3,27.4
                        C65.7,99.6,89.3,93.5,114.2,87.1z M171.8,32c0,0.9-0.2,1.4,0,1.6c8.4,10.1,16.7,20.2,25.2,30.2c0.6,0.8,2.1,1.6,2.9,1.4
                        c9.6-2.4,19.1-5,29.6-7.8c-9.3-11.1-17.9-21.4-26.7-31.6c-0.7-0.8-2.5-1.2-3.6-0.9C190,27.1,180.8,29.6,171.8,32z M94.2,51.9
                        c9.2,11,17.8,21.2,26.4,31.3c0.7,0.8,2.5,1.5,3.5,1.2c9.4-2.3,18.8-4.8,28.9-7.4c-9.1-10.9-17.4-21-26-30.9
                        c-0.9-1.1-3.4-1.7-4.9-1.3C113.2,46.9,104.4,49.3,94.2,51.9z M236.6,113.7c-6.1,0-10.9,0.7-15.5-0.1c-13-2.4-20.1,2.8-24,15.2
                        c-2.5,8.1-7.1,15.5-11.1,24.1c10.6,0,20.2,0.1,29.8-0.1c1.1,0,2.6-1,3.1-2C224.7,138.8,230.3,126.8,236.6,113.7z M107.1,152.8
                        c8.3,0,15.3-0.5,22.3,0.1c7.2,0.7,11.2-1.6,14-8.6c4-10.1,9.2-19.7,14.4-30.6c-10.9,0-20.6-0.1-30.3,0.1c-0.9,0-2.1,1.1-2.6,2
                        C119.1,127.7,113.4,139.7,107.1,152.8z M190.5,67.4c-9.2-11-17.9-21.6-26.8-32c-0.7-0.8-2.5-1.2-3.6-1c-8.8,2.1-17.6,4.4-27.4,6.9
                        c9.4,11.3,18.1,21.8,26.9,32.2c0.7,0.8,2.5,1.3,3.6,1C172.1,72.3,180.9,69.9,190.5,67.4z M196.5,113.7c-6.8,0-12.3,0.6-17.7-0.1
                        c-9.4-1.2-15.6,1.3-18.3,11.2c-1.3,4.8-4.3,9.2-6.5,13.8c-2.1,4.5-4.2,9-6.7,14.2c9.9,0,18.7,0.1,27.5-0.1c1.3,0,3.3-0.8,3.8-1.9
                        C184.5,139,190.2,127,196.5,113.7z M261.6,152.5c0-13.1,0-25.7,0-38.7c-5.1,0-9.8-0.1-14.4,0.1c-1.1,0-2.8,0.8-3.3,1.8
                        c-5.9,12-11.7,24.1-17.7,36.9C238.5,152.5,249.9,152.5,261.6,152.5z M253.6,50.7c-3.7-32.9-26.3-37-44.1-28.2
                        c6.2,7.4,12.3,14.8,18.4,22c10,11.8,10,11.8,24.8,6.8C253,51.2,253.1,51,253.6,50.7z"/>
                    <path className="st1" d="M42.6,160.8c73.5,0,146.3,0,219.5,0c0,10,0,19.8,0,29.6c0,21.6,0.1,43.2-0.2,64.8
                        c-0.2,15.8-10.7,26.1-26.5,26.1c-55.4,0.1-110.7,0.1-166.1,0c-15,0-26.2-9.7-26.5-25C42.1,224.8,42.6,193.2,42.6,160.8z"/>
                    <path className="st1" d="M42.9,114.1c24.9,0,49.2,0,74.4,0c-2.5,5.4-4.6,10.1-6.8,14.8c-3.3,6.9-6.5,13.8-10,20.6
                        c-0.7,1.4-2.7,3.2-4.1,3.2c-17.7,0.2-35.3,0.1-53.4,0.1C42.9,139.8,42.9,127.1,42.9,114.1z"/>
                    <path className="st2" d="M114.2,87.1c-24.9,6.4-48.5,12.5-72.6,18.7c-2.1-9.4-6.2-17.8-3-27.4c2.7-8,7.6-13.9,15.5-16.5
                        c9.6-3.2,19.6-5.5,29.5-7.9c1.2-0.3,3.3,0.3,4.1,1.2C96.3,65.5,104.9,75.9,114.2,87.1z"/>
                    <path className="st1" d="M171.8,32c9.1-2.4,18.3-4.8,27.5-7.1c1.1-0.3,2.9,0.2,3.6,0.9c8.8,10.2,17.4,20.5,26.7,31.6
                        c-10.5,2.8-20,5.4-29.6,7.8c-0.8,0.2-2.3-0.6-2.9-1.4c-8.5-10-16.8-20.1-25.2-30.2C171.6,33.4,171.8,32.9,171.8,32z"/>
                    <path className="st1" d="M94.2,51.9c10.1-2.6,19-5.1,27.9-7.1c1.5-0.4,4,0.2,4.9,1.3c8.6,9.9,16.9,20,26,30.9
                        c-10.1,2.6-19.5,5.1-28.9,7.4c-1,0.3-2.8-0.4-3.5-1.2C112,73.2,103.5,62.9,94.2,51.9z"/>
                    <path className="st2" d="M236.6,113.7c-6.3,13.1-11.9,25.1-17.7,37c-0.5,1-2,2-3.1,2c-9.6,0.2-19.1,0.1-29.8,0.1
                        c4-8.6,8.6-16,11.1-24.1c3.9-12.4,11-17.6,24-15.2C225.7,114.4,230.5,113.7,236.6,113.7z"/>
                    <path className="st2" d="M107.1,152.8c6.3-13.1,11.9-25.1,17.7-37c0.4-0.9,1.7-2,2.6-2c9.7-0.1,19.4-0.1,30.3-0.1
                        c-5.2,10.9-10.4,20.5-14.4,30.6c-2.7,6.9-6.7,9.3-14,8.6C122.5,152.3,115.4,152.8,107.1,152.8z"/>
                    <path className="st2" d="M190.5,67.4c-9.6,2.5-18.4,4.9-27.3,7.1c-1.1,0.3-2.9-0.2-3.6-1c-8.8-10.4-17.5-20.9-26.9-32.2
                        c9.7-2.5,18.5-4.8,27.4-6.9c1.1-0.3,2.9,0.2,3.6,1C172.6,45.8,181.3,56.4,190.5,67.4z"/>
                    <path className="st1" d="M196.5,113.7c-6.3,13.2-12,25.2-17.8,37.1c-0.5,1-2.5,1.8-3.8,1.9c-8.8,0.2-17.6,0.1-27.5,0.1
                        c2.5-5.2,4.5-9.8,6.7-14.2c2.2-4.6,5.1-9,6.5-13.8c2.7-9.9,9-12.4,18.3-11.2C184.2,114.3,189.7,113.7,196.5,113.7z"/>
                    <path className="st1" d="M261.6,152.5c-11.7,0-23.2,0-35.5,0c6.1-12.7,11.8-24.8,17.7-36.9c0.5-0.9,2.1-1.7,3.3-1.8
                        c4.7-0.2,9.3-0.1,14.4-0.1C261.6,126.8,261.6,139.4,261.6,152.5z"/>
                    <path className="st2" d="M253.6,50.7c-0.5,0.4-0.7,0.6-0.8,0.6c-14.8,5-14.8,5-24.8-6.8c-6.2-7.3-12.3-14.6-18.4-22
                        C227.3,13.6,250,17.7,253.6,50.7z"/>
                </g>
                <text transform="matrix(1 0 0 1 101.1598 234.346)" className="st3 st4">{count}</text>
                <text transform="matrix(1 0 0 1 114.7451 273.2471)" className="st3 st5">videos</text>
            </svg>
        </Link>
    )
}

export default VideoSvg;