import React, { Component } from 'react';
import { YearList } from './YearList';
import PhotoUtils from '../../shared/PhotoUtils';

export class YearListContainer extends Component {
	constructor(props){
		super(props);
		this.createDateList = this.createDateList.bind(this);
	}

	createDateList(){
		var startYear = 2006;
		var endYear = new Date().getFullYear();
		var selected = this.props.year + this.props.month;
		
		var years = [];

		for(var year = startYear; year <= endYear; year++){
			var months = []
			for(var month = 1; month <= 12; month++){
				const thisYear = String(year);
				const thisMonth = PhotoUtils.returnMonthString(month);
				months.push({
					title: PhotoUtils.returnMonthFromString(month),
					year: thisYear,
					month: thisMonth, 
					url: '/' + thisYear + '/' + thisMonth + '/',
					active: PhotoUtils.isDateInScope(year,month),
					selected: (thisYear+thisMonth === selected) ? true : false
				})
			}
			// Add the months to the years:
			years[year] = {
				year: year,
				months: months
			}
		}
		
		return years;

	}

	render(){
		return(
			<YearList 
				years={this.createDateList()}
				 />
		)

	}

} 