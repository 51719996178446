import React, { Component } from 'react';

import TagLoop from './tag/TagLoop';
import EquipmentLoop from './equipment/EquipmentLoop';
import MapLink from './mapLink/MapLink';

export class PhotoPortrait extends Component{

	render(){

		const alternate1 = this.props.alternate ? ' medium-order-2' : '';
		const alternate2 = this.props.alternate ? ' medium-order-1' : '';

		return(
			<div className="photo major-section  grid-container">
			<div className="grid-x grid-margin-x">
				<div className={"cell small-12 medium-6" + alternate1}>
					<img src={this.props.content.filename}
					 alt={this.props.content.title}
					 width={this.props.content.size.width}
					 height={this.props.content.size.height} />
				</div>

				<div className={"cell small-10 small-offset-1 medium-6 medium-offset-0" + alternate2}>
						{!!this.props.content.title && <h2>{this.props.content.title}</h2>}

						<div className="description"
					dangerouslySetInnerHTML={{__html: this.props.content.description}}></div>

					
					<TagLoop tags={this.props.content.tags} />
			        
			        <EquipmentLoop 
			        	equipment={this.props.content.equipment}
			        	photoTaken={this.props.photoTaken}
			        	id={this.props.content.id} />

			        <MapLink lat={this.props.content.location.lat}
	        		 lng={this.props.content.location.lng}
	        		 id={this.props.content.id} />

					
				</div>
			</div>
		</div>
		)
	}

}