import React, { Component } from 'react';

import PhotoUtils from '../shared/PhotoUtils';
import dateService from '../../services/date';

import Date from './Date';
import NextPrevious from './nextprevious/NextPrevious';
import { HistoryContainer } from './history/HistoryContainer';
import Loading from '../shared/Loading';
import Error from '../shared/Error';
import SocialMeta from './SocialMeta';

export class DateRoute extends Component {

	constructor(props){
		super(props);
		this.state = {
			date: {
				content: []
			},
			loadedDate: ''
		}
		this.setDate = this.setDate.bind(this);

		// Redirect if the old URL is used:
		if (PhotoUtils.isDate(window.location.search.replace("?", ""))){
			this.props.history.replace("/" + window.location.search.replace("?", ""));
		}

	}

	componentWillReceiveProps(nextProps) {
		this.props = nextProps;

		// scroll to the top of the page
		window.scrollTo(0, 0);

		// if the new location is not the current date loaded, then get new location:
		if (nextProps.location.pathname !== `/${this.state.loadedDate}`){
			this.setState({
				isLoaded: false
			})
			this.fetchDate();
		}
	}

	componentDidMount() {
		this.fetchDate();
	}

	// Chooses the content for social meta to pass along:
	socialMetaTags(){

		if (this.props.match.params.anchorPhotoId){
			const anchorId = this.props.match.params.anchorPhotoId;
			const found = this.state.date.content.find(content =>
				String(content.id) === String(anchorId)
			);
			
			if(found){
				return found;
			}
		}

		return this.state.date.content[0];
	}

	setDate(dateData){
		// if date does not exist, return;
		if (dateData.active !== 1){
			console.log(dateData);
			this.setState({error: `${dateData.formatdate} does not have any photos.`})
			return;
		}
		
		this.setState({ 
			date: dateData,
			loadedDate: dateData.date,
			isLoaded: true
		});

		//Update URL if the random or home link was clicked
		if (this.props.history.location.pathname === "/random" ||
			this.props.history.location.pathname === "/"){
			this.props.history.replace("/" + dateData.date);
		}
	}

	fetchDate(){

		const { date } = this.props.match.params
		let response;

		if (date === "random"){
			response = dateService.getRandomPhoto();
		}else if ( PhotoUtils.isDate(date)  ){
			response = dateService.getPhotoByDay( date );
		}else{
			response = dateService.getTodaysPhoto();
		}

		response
			.then(response =>{
				this.setDate(response.date);
			})
			.catch(error =>{
				console.log("Error!", error);
				this.setState({
					isLoaded: true,
					error: "Error loading content for day."
				})
			});
	}

	render() {
    	const {error, isLoaded} = this.state;
		if (error){
			return( <Error reason={error} />)
		}else if (!isLoaded){
			return( <Loading /> )
		}else{
			const { date } = this.state;
			return(
				<>
					<SocialMeta date={date.formatdate} photo={this.socialMetaTags()} />

					<Date 
						date={date} 
						anchorPhotoId={this.props.match.params.anchorPhotoId}
						/>
					<div className="grid-container">
						<div className="grid-x">
							<div className="cell">
								<NextPrevious
									date={date.date}
									next={date.next}
									previous={date.previous} />							
							</div>
						</div>
					</div>

					<div className="photo-history major-section">
						<div className="grid-container">
							<HistoryContainer date={date.date} />
						</div>
					</div>
				</>
			);
		}
	}

}