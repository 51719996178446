import React, { Component } from 'react';
import {Helmet} from "react-helmet";

import { SearchResultsContainer } from './searchResults/SearchResultsContainer';
import { SearchBarContainer } from './searchBar/SearchBarContainer';
import { TopTagsContainer } from './topTags/TopTagsContainer';

export class TagRoute extends Component {
	constructor(props){
		super(props);
		this.handleEnterSearch = this.handleEnterSearch.bind(this);
		this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
		this.state = {
			currentTag: this.props.match.params.tag,
			currentPage: this.props.match.params.page ? parseInt(this.props.match.params.page) : 1
		}
	}

	componentWillReceiveProps(nextProps) {
    	this.props = nextProps;
    	this.setState({
			currentTag: this.props.match.params.tag,
			currentPage: this.props.match.params.page
    	})
	}

	// if "Enter" was pressed on search
	handleEnterSearch(slug){
		this.setState({
			currentTag: slug,
			currentPage: 0
		})
		this.props.history.push("/tag/" + slug);
	}

	render(){

		return(

			<div>
				<Helmet>
	                <title>Tags - Jesse.Photo</title>
	            </Helmet>
				<div className="grid-container">
					<SearchResultsContainer 
						history={this.props.history}
						slug={this.state.currentTag}
						currentPage={this.state.currentPage} />
				
					<SearchBarContainer 
						handleEnterSearch={this.handleEnterSearch}
					/>
				</div>
				
				<TopTagsContainer />
				
			</div>

		)


	}



}