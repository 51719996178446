import axios from 'axios';
const baseURL = "https://api.jesse.photo/api/";

const getAllTags = () =>{
    const request = axios.get(`${baseURL}tags/`);
    return request.then(response => response.data);
}

const getTagBySlug = (slug) =>{
    const request = axios.get(`${baseURL}tags/optimized2.php?tag=${slug}`);
    return request.then(response => response.data);
}

const getTopTags = (count) =>{
    const request = axios.get(`${baseURL}tags/?top=${count}`);
    return request.then(response => response.data);
}

export default { getAllTags, getTagBySlug, getTopTags }