import React  from 'react';

import { PhotoContainer } from './photo/PhotoContainer';
import PhotoUtils from '../shared/PhotoUtils';

const Date = ({date, anchorPhotoId}) => {
	return(
		<div className="date major-section">
			<h1>
				<span className="hide-for-small-only">{date.dayofweek}, </span>
				{PhotoUtils.formatFriendlyDate(date.date)}
			</h1>

			{date.description && 
			<div className="grid-container">
				<div className="cell date-description" 
					dangerouslySetInnerHTML={{__html: date.description}}></div>
			</div>
			}
			{date.content.map((singleContent, i) => { 
				const isAnchor = (String(singleContent.id) === anchorPhotoId && i !== 0);
				return(
					<PhotoContainer 
						content={singleContent}
						photoNumber={i}
						key={singleContent.id} 
						isAnchor={isAnchor}/>
				)
			})}
		</div>
	);
}

export default Date;