import React from 'react';
//import PhotoThumbnail from './PhotoThumbnail';
import PhotoThumbnail from '../../shared/PhotoThumbnail';
import PhotoUtils from '../../shared/PhotoUtils';

const History = ({photoCollection, currentDate}) =>{
	return(
		<div className="photo-history">
			<ul className="menu">
				{photoCollection.map((photo, i) => {
					
					const active = (photo.date === currentDate) ? 'active' : '';
					return(
						<li key={photo.id}>
							<PhotoThumbnail
								url={`/${photo.date}`}
								image={photo.thumbnail}
								text={PhotoUtils.getYear(photo.date)}
								assignedClass={ active }
								key={photo.id}
								/>
						</li>
					)
				})}
			</ul>
		</div>
	)
}

export default History;