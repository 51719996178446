import React, { Component } from 'react';

import { Calendar } from './Calendar';
import Loading from '../../shared/Loading';
import Error from '../../shared/Error';

import calendarService from '../../../services/calendar';

class CalendarContainer extends Component {

	constructor(props){
		super(props);

		this.state = {
			yearMonth: '',	//string
			result: [],
			isLoaded: false
		}

	}

	componentWillReceiveProps(nextProps) {
    	this.props = nextProps;
    	this.fetchMonth();
	}

	componentDidMount() {
		this.fetchMonth();
	}


	setMonth(yearmonth, result){
		this.setState({
			yearMonth: yearmonth,
			result: result,
			isLoaded: true
		});
		// Scroll to the top of page:
		window.scrollTo(0, 0);
	}

	fetchMonth(){
		const year = this.props.year;
		const month = this.props.month;
		const yearmonth = year + month;

		if (this.state.yearMonth !== (yearmonth)){
			this.setState({isLoaded: false});

			calendarService.getCalendar(year, month)
				.then(response => {
					var allDays = this.addStartingDays(response);
	            	this.setMonth(year+month, allDays);
				})
				.catch(error => {
					console.log("Calendar Error", year, month, error);
					this.setState({error: true})
				})
		}
	}

	// Adds beginning days to the array:
	addStartingDays(result){

		var tempArray = result.days;

		// Add beginning days:
		for (var i = 0; i < result.monthStartsOn; i++) {
			tempArray.unshift({day:0});
		}

		// Break Days into Weeks:
		let weeks = [];
		let weekInt = 0;
		let dayCount = 1;
		for (var i2 = 0; i2 < tempArray.length; i2++){

			// create subarray if it doesn't exist:
			if (!weeks[weekInt]){ weeks[weekInt] = []; }

			// Push day to week array:
			weeks[weekInt].push(tempArray[i2]);		

			dayCount++;
			// New Week:
			if (dayCount === 8){
				dayCount = 1;
				weekInt++;
			}
		}
		
		// Add Final Days:
		const endDaysNumber = ( ((weekInt+1)*7) - tempArray.length);

		// 6 days + day 0, is a full week.
		if (endDaysNumber < 7){
			for (var i3 = 0; i3 < endDaysNumber; i3++) {
				weeks[weekInt].push({day:0});
			}
		}

		result.weeks = weeks;
		
		return result;
	}

	render(){

		const { yearMonth, isLoaded, error, result } = this.state;
		if(error){
			return (
				<Error reason="Error loading the calendar." />
			)
		}
		else if (isLoaded){
			return(
				<Calendar 
					yearMonth={yearMonth}
					data={result} />	
			)
		}else{
			return(<Loading />);
		}

	}

}

export default CalendarContainer;