import React, { Component } from 'react';

import SearchResults from './SearchResults.js';
import Pagination from './Pagination';
import Loading from '../../shared/Loading';
import Error from '../../shared/Error';

import tagService from '../../../services/tag';

export class SearchResultsContainer extends Component {

	constructor(props){
		super(props);

		this.state = {
			imageCollection: [],
			currentPage: this.props.currentPage,
			itemsPerPage: 35,
			isLoaded: false,
			slug: ''
		}

		this.setCollection = this.setCollection.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		const oldSlug = this.props.slug;
		this.props = nextProps;
		
		// load new slug if needed
		if(this.props.slug !== oldSlug){
			this.fetchResults();
		}

    	//Scroll to the top of the page:
		window.scrollTo(0, 0);
	}

	componentDidMount() {
		this.fetchResults();
		window.scrollTo(0, 0);
	}

	fetchResults(){
		this.setState({
			imageCollection: [],
			tagName: '',
			isLoaded: false,
			error: false
		});

		tagService.getTagBySlug(this.props.slug)
			.then(response => {
				this.setCollection(response);
			})
			.catch(error =>{
				console.log("Get all Tags Error!", error);
                this.setState({
                    isLoaded: true,
                    error: "Error fetching search results"
                })
			})
			
	}

	setCollection(results){

		if(results.tag === "none"){
			this.setState({error: "The tag could not be found"});
			return;
		}

		this.setState({
			imageCollection: results.results,
			tagName: results.tag,
			isLoaded: true,
			currentPage: this.props.currentPage ? this.props.currentPage : 1
		});
	}

	getPhotosForCurrentPage(){
		// Logic for displaying todos
	    const indexOfLastPhoto = this.state.currentPage * this.state.itemsPerPage;
	    const indexOfFirstPhoto = indexOfLastPhoto - this.state.itemsPerPage;
	    const pagePhotos = this.state.imageCollection.slice(indexOfFirstPhoto, indexOfLastPhoto);
	    
	    return pagePhotos;
	}

	getNumberOfPagesForPagnation(){
		const pageNumbers = [];
	    for (let i = 1; i <= Math.ceil(this.state.imageCollection.length / this.state.itemsPerPage); i++) {
	      pageNumbers.push(i);
		}
		return pageNumbers;
	}

	pagnationClick(i){

		const page = (i+1);

		this.setState({
			currentPage: page
		});
		//Scroll to top of page:
		window.scrollTo(0, 0);
		//set the history
		this.props.history.push(`/tag/${this.props.slug}/${page}`);
	}

	render(){

		const { isLoaded, imageCollection, tagName, error, currentPage } = this.state;
		const isResults = (imageCollection.length === 0) ? false : true;
		const isHome = (this.props.slug) ? false : true;

		if (isHome){
			return (<><h1>Tags</h1></>);
		}
		else if (error){
			return(
				<Error reason={error} />
			)
		}
		else if (isLoaded && isResults){
			return(
				<>
					<SearchResults 
						tagName={tagName}
						collection={this.getPhotosForCurrentPage()}
						totalPhotos={imageCollection.length}
						numberOfPages={Math.ceil(this.state.imageCollection.length / this.state.itemsPerPage)}
						currentPage={currentPage} />
					<Pagination
						numberOfPages={this.getNumberOfPagesForPagnation()}
						handlePaginationClick={(i) => this.pagnationClick(i)}
						currentPage={currentPage} />
				</>
					
			)
		}else if (!isLoaded && !isResults){
			return(
				<Loading />
			)
		}

	}

}