import React from 'react';
import {Helmet} from "react-helmet";

const SocialMeta = ({date, photo}) =>{

    const photoTitle = () =>{
        if (photo.title !== ""){
            return `${photo.title}, ${date}`;
        }
        
        return `Photo of the day for ${date}`;
    }

    const description = () =>{
        return `Jesse's Daily Photo Blog, photo for ${date}.`;
    }

    const url = () =>{
        return `https://jesse.photo/${photo.date}/${photo.id}`;
    }

    return(
        <Helmet>
            <title>{`${date} - Jesse.Photo`}</title>
            <meta itemprop="name" content={photoTitle()} />
            <meta itemprop="description" content={description()} />
            <meta itemprop="image" content={photo.filename} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@jesseclark" />
            <meta name="twitter:title" content={photoTitle()} />
            <meta name="twitter:description" content={description()} />
            <meta name="twitter:creator" content="@jesseclark" />

            <meta name="twitter:image:src" content={photo.filename} />

            <meta property="og:title" content={photoTitle()} />
            <meta property="og:type" content="article" />
            <meta property="og:url" content={url()} />
            <meta property="og:image" content={photo.filename} />
            <meta property="og:description" content={description()} />
            <meta property="og:site_name" content="Jesse.Photo, Photo of the Date" />
            <meta property="article:published_time" content={photo.date} />

        </Helmet>
    )
}

export default SocialMeta;