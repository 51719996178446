import React from 'react';
import { Link } from 'react-router-dom';

const CompassSvg = ({count}) => {
    return(
        <Link to="/map">
            <svg version="1.1" id="compass" x="0px" y="0px" viewBox="0 0 300 300" >
                <polygon className="st1" points="222.7,77.3 185.3,150 222.7,222.7 150,185.3 77.3,222.7 114.7,150 77.3,77.3 150,114.7 "/>
                <polygon className="st1" points="150,6.5 184.9,115.1 293.5,150 184.9,184.9 150,293.5 115.1,184.9 6.5,150 115.1,115.1 "/>
                <circle className="st0" cx="150.7" cy="153.6" r="64.2"/>
                <circle className="st5" cx="150.7" cy="153.6" r="46.1"/>
                <text transform="matrix(1 0 0 1 120.2773 155.6729)" className="st2 st3">{count}</text>
                <text transform="matrix(1 0 0 1 129.0664 170.1494)">
                    <tspan x="0" y="0" className="st2 st4">Mapped </tspan>
                    <tspan x="3.5" y="14.4" className="st2 st4">Photos</tspan>
                </text>
            </svg>
        </Link>
    )
}

export default CompassSvg;