import React from 'react';

const DateSvg = ({count}) => {

    return(
        <svg version="1.1" id="calendar" x="0px" y="0px" viewBox="0 0 300 300">
            <g>
                <path d="M28.9,32c0,6.3-0.1,11.6,0,16.9c0.3,11.4,10,18.4,19.7,14.2c5.4-2.3,8.5-6.5,8.6-12.5c0.1-6.9,0-13.7,0-20.8
                    c4.9,0,9.4,0,14.5,0c0,5-0.1,9.9,0,14.8c0,2.8,0,5.6,0.5,8.3C73.9,60.7,79.7,64.7,88,64c6.6-0.6,11.8-6.5,12-13.6
                    c0.1-3.4,0-6.9,0-10.3c0-3.3,0-6.6,0-10.2c4.8,0,9.3,0,14.4,0c0,2,0,4.1,0,6.2c0,4.8-0.2,9.7,0.1,14.5c0.4,8.1,6.5,13.6,14.6,13.5
                    c7.8-0.2,13.5-5.9,13.6-14c0.1-6.7,0-13.4,0-20.4c5,0,9.5,0,14.6,0c0,7-0.1,13.8,0,20.7c0.1,6.4,4.6,12.1,10.5,13.4
                    c6.6,1.5,13.1-1.1,15.9-7c1.3-2.7,1.7-5.9,1.8-8.9c0.3-6,0.1-11.9,0.1-18.2c4.8,0,9.3,0,14.5,0c0,6.4-0.1,12.8,0,19.3
                    c0,2.1,0.2,4.4,0.9,6.4c2.3,6.3,8.6,9.6,15.8,8.5c5.9-0.9,10.8-6,11.1-12.4c0.3-7.1,0.1-14.2,0.1-21.7c5,0,9.6,0,14.9,0
                    c0,2.1,0,4.3,0,6.5c0,4.7,0,9.4,0,14.2c0.1,7.4,5.6,13.2,12.8,13.5c8.3,0.4,14.5-4.5,14.9-12.4c0.4-6.2,0.1-12.4,0.1-19.8
                    c5.8,3.1,9.2,7.1,11,12.4c0.8,2.4,1.1,5,1.1,7.5c0.1,72.9,0,145.9,0.1,218.8c0,11.5-10.1,20.7-21.7,20.7
                    c-72.3-0.2-144.6-0.1-216.9-0.1c-3.7,0-7.5,0.3-11-0.4C23,288.5,17,280.4,17,269.3c0-33.2,0-66.3,0-99.5c0-39,0-78,0-117.1
                    C16.9,44,20.4,37.1,28.9,32z M272.3,96.7c-81.7,0-162.8,0-244.1,0c-0.1,1.2-0.1,2.2-0.1,3.2c0,56.4,0,112.7,0,169.1
                    c0,8.1,3.6,11.6,11.7,11.6c73.6,0,147.1,0,220.7,0c8.1,0,11.8-3.8,11.8-12c0-55.5,0-110.9,0-166.4
                    C272.3,100.5,272.3,98.9,272.3,96.7z"/>
                <path d="M96.7,34.7c0,4.8,0,9.7,0,14.5c0,6.5-4.1,11.1-9.9,11.5c-6.1,0.4-11.1-3.6-11.4-10.2c-0.4-10.4-0.4-20.9-0.1-31.3
                    c0.2-6.6,5.4-10.7,11.3-10.3c5.8,0.4,9.9,4.9,10,11.4C96.7,25,96.6,29.9,96.7,34.7C96.6,34.7,96.7,34.7,96.7,34.7z"/>
                <path d="M139.5,34.6c0,4.8,0.1,9.7,0,14.5c-0.1,6.7-4.6,11.5-10.7,11.6c-6.1,0.1-10.7-4.5-10.8-11.4c-0.1-9.9-0.1-19.9,0-29.8
                    c0.1-6.3,4.6-10.7,10.6-10.8c5.9-0.1,10.5,4.2,10.8,10.6C139.7,24.4,139.5,29.5,139.5,34.6z"/>
                <path d="M182,34.8c0,4.8,0,9.7,0,14.5c0,6.6-3.9,11-9.8,11.4c-6,0.3-11.2-4-11.4-10.4c-0.3-10.4-0.3-20.9,0-31.3
                    c0.2-6.1,5.3-10.4,11.1-10.2c6,0.2,10,4.6,10.1,11.1c0,2.5,0,5.1,0,7.6C181.9,30,181.9,32.4,182,34.8C181.9,34.8,182,34.8,182,34.8
                    z"/>
                <path d="M32.6,34.4c0-5-0.1-9.9,0-14.9C32.7,13.4,36.9,9,42.8,8.8c5.9-0.2,11,4.2,11.1,10.2c0.2,10.4,0.2,20.9,0,31.4
                    c-0.1,6-5.1,10.4-11,10.3c-5.8-0.1-10.1-4.4-10.2-10.6C32.6,44.9,32.6,39.7,32.6,34.4C32.6,34.4,32.6,34.4,32.6,34.4z"/>
                <path d="M224.8,35.1c0,5,0.1,9.9,0,14.9c-0.1,6.2-4.6,10.7-10.6,10.8c-5.8,0.1-10.7-4.6-10.8-10.6c-0.1-10.3-0.1-20.7,0-31
                    c0.1-5.9,4.8-10.3,10.6-10.4c5.9,0,10.3,4.2,10.7,10.2c0.1,2.7,0.1,5.4,0.1,8C224.8,29.7,224.8,32.4,224.8,35.1
                    C224.8,35.1,224.8,35.1,224.8,35.1z"/>
                <path d="M267.5,34.7c0,5,0,9.9,0,14.9c0,6.4-4.5,11-10.6,11.1c-6.1,0.1-10.7-4.6-10.7-11c-0.1-9.9,0-19.9,0-29.8
                    c0-6.5,4.5-11.1,10.6-11.1c6.1,0,10.6,4.6,10.7,11c0,2.5,0,5.1,0,7.6C267.5,29.9,267.5,32.3,267.5,34.7
                    C267.5,34.7,267.5,34.7,267.5,34.7z"/>
                
                <text transform="matrix(1 0 0 1 62.7029 191.8125)" className="st2">{count}</text>
                <text transform="matrix(1 0 0 1 60.4592 253.4023)" className="st3">dates added</text>
            </g>
        </svg>
    )
}

export default DateSvg;