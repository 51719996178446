import React from 'react';

const PhotoSvg = ({count}) => {
    return(
        <svg version="1.1" id="photo" viewBox="0 0 300 300">
            <rect x="3.5" y="46.2" className="st0" width="293" height="201.5"/>
            <circle className="st1" cx="70.1" cy="111.6" r="32.1"/>
            <polygon className="st2" points="3.5,247.7 131.1,136.1 178.5,175.5 254.4,114.4 296.5,151.8 296.5,247.7 "/>
            <text transform="matrix(1 0 0 1 136.3372 235.0159)" className="st3 st4 st5">{count}</text>
            <text transform="matrix(1 0 0 1 189.0853 80.1222)" className="st4 st6">photos</text>
        </svg>
    )
}

export default PhotoSvg;