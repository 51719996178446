import React, {useState} from 'react';

const DateGraph = ({allDates, daysInCurrentYear, photosInCurrentYear, daysFromBeginning}) => {

    const [selectedYear, setSelectedYear] = useState(2021);
    const [selectedDays, setSelectedDays] = useState(365);
    const [selectedPhotos, setSelectedPhotos] = useState(photosInCurrentYear);

    const handleBarClick = (year) => {
        setSelectedYear(year.year);
        setSelectedDays((year.totalDays) ? year.totalDays : 365);
        setSelectedPhotos(year.photos);
    }

    // The maximum height of the bar if 100% of the days are filled
    const maxHeight = 252;

    // Return the current X value and Increment it:
    // This is used as we loop over the years for the bars and then for the labels
    let labelX;
    const currentX = () =>{
        labelX += 40;
        return labelX - 40;
    }

    // Sets the value of X, used to set the value for the labels and then
    // reset the value before the bars
    const setX = (reset) =>{
        labelX = reset;
    }

    // Returns the Y value of the front bar given the photos and days
    const getY = (datesWithPhotos, daysInYear) =>{
        const height = (datesWithPhotos * maxHeight) / daysInYear;
        const answer = 45 + (maxHeight - height);
        return answer;
    }

    // Front height + Front y = 297
    // Returns the Front Height given Y Value:
    const getHeightFromY = (y) =>{
        return 297-y;
    }

    // Full years plus the number of photos for that year
    // 2006 is not included and hard coded in since it's height is 
    // shorter because of the starting date.

    let yearNumbers = [
        {"year": 2007, photos:299},
        {"year": 2008, photos:287, totalDays:366},
        {"year": 2009, photos:336},
        {"year": 2010, photos:301},
        {"year": 2011, photos:235},
        {"year": 2012, photos:255, totalDays:366},
        {"year": 2013, photos:282},
        {"year": 2014, photos:282},
        {"year": 2015, photos:220},
        {"year": 2016, photos:210, totalDays:366},
        {"year": 2017, photos:299},
        {"year": 2018, photos:267},
        {"year": 2019, photos:304},
        {"year": 2020, photos:280, totalDays: 366},
        {"year": 2021, photos:165},
        {"year": 2022, photos:186},
        {"year": 2023, photos:parseInt(photosInCurrentYear)}
    ]

    return(
        <div className="cell">
            <h3 className="text-center">Number of Days Represented Each Year</h3>
            <p className="text-center">
                <strong>{daysFromBeginning}</strong> days since the project started |
                <strong> {allDates}</strong> days with photos |
                <strong> {selectedYear}: </strong> 
                <strong> {selectedPhotos} / {selectedDays}</strong> days
            </p>

            <svg version="1.1" x="0px" y="0px" viewBox="0 0 800 350" id="graph">
                
                {setX(71)}
                <g id="Labels">
                    <text
                        transform={`matrix(6.123234e-17 1 -1 6.123234e-17 ${currentX()} 302.688)`}
                        className={selectedYear === 2006 ? 'active' : ''}>2006</text>
                    
                    {yearNumbers.map(year =>
                        <text 
                            transform={`matrix(6.123234e-17 1 -1 6.123234e-17 ${currentX()} 302.688)`} 
                            className={selectedYear === year.year ? 'active' : ''}
                            key={`label-${year.year}`}>
                                {year.year}
                        </text>
                    )}
                </g>
                    
                {setX(63)}
                <g>
                    <g className={selectedYear === 2006 ? 'active' : ''}
                        onClick={() => handleBarClick({"year": 2006, "photos":115, "totalDays":131})}>
                        <rect x={labelX} y="206" className="back" width="20" height="54"/>
                        <rect x={currentX()} y="217" className="front" width="20" height="79.4"/>
                    </g>

                    {yearNumbers.map(year =>
                        <g className={selectedYear === year.year ? 'active' : ''}
                        onClick={() => handleBarClick(year)}
                        key={`bar-${year.year}`}>
                            <rect 
                                className="back"
                                width="20"
                                x={labelX} 
                                y={year.backY ||  "45"}
                                height={year.backHeight || maxHeight}
                                />
                            <rect
                                className="front"
                                width="20"
                                x={currentX()} 
                                y={getY(year.photos, (year.totalDays || 365), year.backHeight)}
                                height={getHeightFromY(getY(year.photos, (year.totalDays || 365), year.backY), year.backY)}
                                />
                                
                        </g>
                    )}
                </g>
                
                <g id="Lines">
                    <line x1="45" y1="297" x2="790" y2="297"/>
                </g>
            </svg>

        </div>

    )
}

export default DateGraph;