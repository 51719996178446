import nonceService from '../../services/nonce';

export class LocalStorageCheck{

	constructor(){
		this.fetchNonceFromServer();
	}

	fetchNonceFromServer(){
		nonceService.getNonce()
			.then(response =>{
				this.compareLocaltoServer(response);
			})
			.catch(error =>{
				console.log("Nonce error", error);
			})
	}

	fetchNonceFromLocalStorage(){
		let result = JSON.parse( localStorage.getItem("nonce") );
		return result;
	}

	compareLocaltoServer(server){
		const local = this.fetchNonceFromLocalStorage();

		if (local === null){
			this.saveLocalStorage(server);
			return;
		}

		if (local.mapCount !== server.mapCount){
			this.saveLocalStorage(server);
			return;
		}

		if (local.tagCount !== server.tagCount){
			this.saveLocalStorage(server);
			return;
		}

		if ((server.date - local.date) > 604800){
			this.saveLocalStorage(server);
			return;
		}

	}

	saveLocalStorage(data){
		console.log("Clearing Local Storage");
		// Clear local storage first:
		localStorage.clear("");

		// Save Nonce 
		localStorage.setItem("nonce", JSON.stringify(data));
	}


}