import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class MonthList extends Component {


	render(){
		const {year, months} = this.props;

		return (
			<div className="cell medium-2 small-6">
				<h4>{year}</h4>
				<ul className="no-bullet">
					{months.map((month, i) => {
						const active = (month.selected) ? 'active' : '';
						if (month.active){
							return(
								<li className={active} key={year + month.month}>
									<Link to={"/calendar" + month.url}>{month.title}</Link>
								</li>
							)
						}else{
							return(
								<li key={year + month.month}>{month.title}</li>
							)
						}
					})}
				</ul>
			</div>
		)

	}

}