import React, { Component } from 'react';
import History from './History';
import Loading from '../../shared/Loading';

import dateService from '../../../services/date';

export class HistoryContainer extends Component {

	constructor(props){
		super(props);

		this.state = {
			photoCollection: [],
			isLoaded: false
		}

		this.setCollection = this.setCollection.bind(this);

		this.fetchHistory();

	}

	setCollection(collection){
		this.setState({
			photoCollection: collection,
			isLoaded: true
		})
	}

	fetchHistory(){

		dateService.getHistoryForDay(this.props.date)
		.then(response => {
			this.setCollection(response)
		})
		.catch(error =>{
			this.setState({
				isLoaded: true,
				error
			})
		})

	}

	render(){
		const { isLoaded } = this.state;

		if (isLoaded){
			return(
				<History 
					currentDate={this.props.date}
					photoCollection={this.state.photoCollection} />
			)
		}else{
			return(
				<Loading />
			)
		}
	}

}