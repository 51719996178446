import React, { Component } from 'react';
import { MonthList } from './monthList/MonthList';

export class YearList extends Component {

	render(){
		const {years} = this.props;

		return(
			<div className="calendar-months major-section additional-content">
				<div className="grid-container">
					<div className="grid-x">
						{years.reverse().map((year, i) => { 
							return(	
								<MonthList 
									year={year.year}
									months={year.months}
									key={year.year} />
							)
						})}
					</div>
				</div>
			</div>
		)

	}

} 