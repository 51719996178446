import React from 'react';
import { Link } from 'react-router-dom';

import PhotoUtils from '../../shared/PhotoUtils';

const NextPrevious = ({month, year}) =>{

    const next = PhotoUtils.getNextMonth(year, month);
    const previous = PhotoUtils.getPreviousMonth(year, month);

    return(
        <div className="grid-container major-section">
            <div className="grid-x grid-margin-x">
                {!! PhotoUtils.isDateInScope(previous.year, previous.month) &&
                    <div className="cell small-6 medium-4 medium-offset-2">
                        <Link to={`/calendar/${previous.year}/${previous.month}`}>
                            &#xab; {previous.text}
                        </Link>
                    </div>
                }
                {!! PhotoUtils.isDateInScope(next.year, next.month) &&
                    <div className="cell small-6 medium-4 text-right">
                        <Link to={`/calendar/${next.year}/${next.month}`}>
                            {next.text} &#xbb;
                        </Link>
                    </div>
                }
            </div>
        </div>
    )
}

export default NextPrevious;