import React from 'react';
import { Link } from 'react-router-dom';

const PhotoThumbnail = ({url, image, text, assignedClass}) => { 

    const style = { backgroundImage: "url(" + image + ")" }
    const theClass = (assignedClass) ? assignedClass : '';
    
    return(
        <div className={`thumbnail ${theClass}`}
             style={style}>
                <Link to={url}>
                    {!! text &&
                        <span>{text}</span>
                    }
                </Link>
        </div>
    )

}

export default PhotoThumbnail;