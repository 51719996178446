import React from 'react';
import Day from './Day';

const Week = ({days}) =>{
	return(
		<div className="week grid-x thumbnail-container">
			{days.map((photo, i) => {
				return(
					<Day date={photo.date}
						 day={photo.day}
						 thumbnail={photo.thumbnail}
						 key={i} />
				)
			})}
		</div>
	)

}

export default Week;