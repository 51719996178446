import React, { useState } from 'react';

import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { LocalStorageCheck } from './components/localStorageCheck/LocalStorageCheck';

import { KeyboardSingletonClass } from './components/shared/KeyboardShortcuts';
import { Navigation } from './components/navigation/Navigation';
import { FooterContainer } from './components/footer/FooterContainer';
import { DateRoute } from './components/dateRoute/DateRoute';
import { MapRoute } from './components/mapRoute/MapRoute';
import { TagRoute } from './components/tagRoute/TagRoute';
import { CalendarRoute } from './components/calendarRoute/CalendarRoute';
import AboutRoute from './components/aboutRoute/AboutRoute';

import "./scss/App.scss";
import PasswordScreen from './components/password';

const App = React.Component = () => {
	const [isLoggedin, setIsLoggedIn] = useState(false)

	const init = () => {
		setIsLoggedIn(true)

		const keys = KeyboardSingletonClass.getInstance();
		
		keys.addShortcut("t", "/");
		keys.addShortcut("r", "/random");
		keys.addShortcut("m", "/map");
		keys.addShortcut("c", "/calendar");

		new LocalStorageCheck();
	}

	return isLoggedin ? (
		<BrowserRouter>
			<Navigation />
			<div className="content">
				<Switch>
					<Route exact path="/" component={DateRoute}/>
					
					<Route path="/map/:id" component={MapRoute} />
					<Route path="/map" component={MapRoute} />
					
					<Route path="/tag/:tag/:page" component={TagRoute} />
					<Route path="/tag/:tag" component={TagRoute} />
					<Route path="/tag" component={TagRoute} />

					<Route path="/calendar/:year/:month" component={CalendarRoute} />
					<Route path="/calendar" component={CalendarRoute} />

					<Route path="/about" component={AboutRoute} />

					<Route path="/:date/:anchorPhotoId" component={DateRoute} />
					<Route path="/:date" component={DateRoute} />

				</Switch>
			</div>
			<FooterContainer />
		</BrowserRouter>
	) : <PasswordScreen authenticated={init} />
}

export default App;
