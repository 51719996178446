import React, { Component } from 'react';
import CalendarContainer from './calendar/CalendarContainer';
import { YearListContainer } from './yearList/YearListContainer'
import { KeyboardSingletonClass } from '../shared/KeyboardShortcuts';
import PhotoUtils from '../shared/PhotoUtils';
import NextPrevious from './nextprevious/NextPrevious';

export class CalendarRoute extends Component {

	constructor(props){
		super(props);
		this.state = {
			year: '',
			month: ''
		}

		this.getMonthYear = this.getMonthYear.bind(this);
		this.setMonthDate = this.setMonthDate.bind(this);
	}

	componentDidMount(){
		this.getMonthYear();
		
	}

	componentWillReceiveProps(nextProps) {
    	this.props = nextProps;
    	this.getMonthYear();
    	
	}

	// Component is unmounting
	componentWillUnmount(){
		const keys = KeyboardSingletonClass.getInstance();
		keys.removeShortcut("ArrowLeft");
		keys.removeShortcut("ArrowRight");
	}

	getMonthYear(){

		if (this.props.match.params.year && this.props.match.params.month){
			//console.log("Setting from params", this.props.match.params);
			this.setMonthDate(
				this.props.match.params.year, 
				this.props.match.params.month);
		}else{
			var MyDate = new Date();
			this.setMonthDate( 
				MyDate.getFullYear() + '', 
				PhotoUtils.returnMonthString( MyDate.getMonth()+1 )
			);
		}
		
	}


	setMonthDate(_year, _month){
		this.setState({
			year: _year,
			month: _month
		})

		this.setKeyboardShortCuts(_month, _year);
	}
	
	setKeyboardShortCuts(month, year){

		const next = PhotoUtils.getNextMonth(year, month);
		const previous = PhotoUtils.getPreviousMonth(year, month);

		const keys = KeyboardSingletonClass.getInstance( this.props );
		if (!PhotoUtils.isDateInTheFuture(`${next.year}-${next.month}-01`)){
			keys.addShortcut("ArrowRight", "/calendar/" + next.year + "/" + next.month + "/");
		}
		keys.addShortcut("ArrowLeft", "/calendar/" + previous.year + "/" + previous.month + "/");
	}


	render(){

		return(
			<div>
				<CalendarContainer
					year={this.state.year}
					month={this.state.month} />
				<NextPrevious 
					year={this.state.year}
					month={this.state.month}  />
				<YearListContainer
					year={this.state.year}
					month={this.state.month} />
			</div>
		)

	}

}