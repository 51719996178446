import React from 'react';

const Pagination = ({numberOfPages, currentPage, handlePaginationClick}) => {

	if (numberOfPages.length > 1){

		return(		
			<nav aria-label="Pagination" className="major-section">
				<ul className="menu pagination text-center">

					{numberOfPages.map((pageNumber, i) => {
						const active = (pageNumber === currentPage) ? 'active' : '';
						return(
							<li onClick={() => handlePaginationClick(i)} 
								className={active}
								aria-label={"Page " + (i+1)}
								key={i}>{pageNumber}</li>
						)
					})}
				</ul>
			</nav>
		)
	}else{
		return(<div></div>);
	}
}


export default Pagination;