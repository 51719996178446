import React, {useState, useEffect} from 'react'
import aboutService from '../../services/about';

import About from './About';
import Loading from '../shared/Loading';

const AboutRoute = () =>{ 

	const [about, setAbout] = useState();

    useEffect(() => {
        aboutService.getAbout()
          .then(response  => {
            setAbout(response);
          })
	}, [])

	const calculateOverAllStats = () => {
		// number of days in 2006 PLUS the days from 2007 to 2018 (including 3 leap years)
		const pastYears = 131 + 4383;
		
		return pastYears + parseInt(about.currentYear);
	}

	if(about){
		return(
			<About 
				stats={about}
				allDates={calculateOverAllStats()}
				/>
		)
		}else{
			return(
				<Loading />
			)
		}
	
}

export default AboutRoute;