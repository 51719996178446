import React from 'react';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';

import DateSvg from './svg/dateSvg';
import CompassSvg from './svg/compassSvg';
import TagsSvg from './svg/tagsSvg';
import PhotoSvg from './svg/photoSvg';
import DateGraph from './svg/dateGraph';
import CameraSvg from './svg/cameraSvg';
import VideoSvg from './svg/videoSvg';

const About = ({stats}) =>{

    return (
        <div className="grid-container major-section about">
            <Helmet>
                <title>About - Jesse.Photo</title>
            </Helmet>
            <div className="grid-x grid-margin-x">
                <div className="cell medium-8">
                    <h1>About</h1>
                    <p>In early 2006 I came across a blog called Ten Years of My Life. The concept was that the author would post a photo every day for ten years. He was a little over half way through when I came across the site.</p>
                    <p>At the time, I was interested in, and I liked the idea of capturing a photo each day as a reminder of what happened. My memory wasn't the greatest, and I felt this would help.</p>
                    <p>In <Link to="/calendar/2006/08/">August 2006</Link>, I posted my first photo on a custom built platform. At the time, Flickr was the most popular photo sharing site, but it didn't have all the functionality I wanted. Over the years, many photo platforms have come and gone. By building this platform, I was able to control how my photos are displayed and the functionality I needed.</p>
                    <p>The "rules" of the photo blog have changed a lot over the years. In the beginning, the photo posted may not have been taken on the date it was posted on. These days, I try to post a picture on the date it was taken.</p>
                </div>
                <div className="cell medium-4">
                    <DateSvg count={stats.count.DateCount} />
                    <PhotoSvg count={stats.count.PhotoCount} />
                    <VideoSvg count={stats.count.VideoCount} />
                </div>
            </div>

            <div className="grid-x">
                <DateGraph 
                    allDates={stats.count.DateCount}
                    daysInCurrentYear={stats.days} 
                    photosInCurrentYear={stats.currentYear}
                    daysFromBeginning={stats.daysFromBeginning} />
            </div>

            <div className="grid-x">
                <div className="cell">
                    <h2>Misc Stats</h2>
                </div>
                <div className="cell medium-4">
                    <CompassSvg count={stats.count.MapCount} />
                </div>
                <div className="cell medium-4">
                    <TagsSvg count={stats.count.TagCount} />
                </div>
                <div className="cell medium-4">
                    <CameraSvg count="17" />
                </div>
            </div>
        </div>
    )
}

export default About;