import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Tag extends Component {

	render(){
		const activeClass = (this.props.active) ? 'selected' : '';
		return (
			<li className={activeClass}>
				<Link to={"/tag/" + this.props.slug}>
					{this.props.tagName}
				</Link>
			</li>
		)
	}

}