import React, { Component } from 'react';
import {Helmet} from "react-helmet";

import Week from './Week';

export class Calendar extends Component {

	render(){

		return(
			<div className="grid-container major-section">
				<Helmet>
	                <title>{this.props.data.title} - Calendar - Jesse.Photo</title>
	            </Helmet>
				
				<h1 className="text-center">{this.props.data.title}</h1>

				<div className="calendar">
					<div className="grid-x thumbnail-container break-above title">
						<div>Sunday</div>
						<div>Monday</div>
						<div>Tuesday</div>
						<div>Wednesday</div>
						<div>Thursday</div>
						<div>Friday</div>
						<div >Saturday</div>
					</div>

					{this.props.data.weeks.map((singleWeek, w) => {
						return(
							<Week days={singleWeek} key={w} />
						)
					})}
				</div>
			</div>
		)

	}

}