export class KeyboardSingletonClass {

  static instance = null;
  altDown = false;
  ctrlDown = false;

  static createInstance() {
      var object = new KeyboardSingletonClass();
      return object;
  }

  static getInstance () {
      if (!KeyboardSingletonClass.instance) {
          KeyboardSingletonClass.instance = KeyboardSingletonClass.createInstance();
      }
      return KeyboardSingletonClass.instance;
  }

  shortcuts = [];
  constructor(){

    document.addEventListener("keydown", event =>{
      if (event.key === "Alt"){
        this.altDown = true;
      }
      if (event.key === "Control"){
        this.ctrlDown = true;
      }
    })

  	document.addEventListener("keyup", event => {
      // if inside an input box:
      if (event.target.type === "text" ||
          event.target.type === "email"){
        return;
      }

      // remove the alt and control down variable:
      if (event.key === "Alt" || event.key === "Control"){
        setTimeout( () => this.removeBoolKey(event.key), 100 );
      }
      
		  
  		if (this.shortcuts[event.key] !== undefined && !this.altDown && !this.ctrlDown){
        window.location = this.shortcuts[event.key];
  		}
  	})
  }

  // adds or updates shortcut:
  addShortcut(letter, path){
  	this.shortcuts[letter] = path;
  }

  // Removes the shortcut from the list
  removeShortcut(letter){
    delete this.shortcuts[letter];
  }

  // Removes control and alt from being down
  // done here so it can have access to "this"
  removeBoolKey(letter){
    switch (letter) {
      case "Alt":
        this.altDown = false;
        break;
    
      default:
          this.ctrlDown = false;
        break;
    }
  }

}
