import React, { Component } from 'react';


export class Footer extends Component {

	clearCache() {
		localStorage.clear();
		document.location.reload()
	}

	render(){
		return (
			<footer id="footer">
				<div className="grid-container minor-section">
					<div className="grid-x">
						<div className="cell">
						<p className="hide-for-small-only">These photographs do not exist for the purpose of art; rather they exist as a reminder of my life and a way for me to share my story with others. The photos here were taken by me (unless otherwise noted) and cannot be used without permission. </p>
						<p>
							<a href="https://developerjesse.com">Jesse Clark</a> | 
							Code last updated {this.props.dateUpdated} | 
							Commit <em>{this.props.commit}</em> | 
							Pipeline <em>#{this.props.pipeline}</em> |
							{this.props.env} | 

							<button onClick={this.clearCache}>Clear Cache</button>
						</p>
						</div>
					</div>
				</div>
			</footer>
		)
	}
}