import React, { Component } from 'react';

import SearchBar from './SearchBar';

import tagService from '../../../services/tag';

export class SearchBarContainer extends Component {

	constructor(props){
		super(props);
		this.state = {
			tags: [],
			searchTerm: '',
			searchResults: [],
			currentIndex: 0
		}

		this.setTags = this.setTags.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handlePress = this.handlePress.bind(this);
		this.handleArows = this.handleArows.bind(this);
		this.handleEnter = this.handleEnter.bind(this);
		this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
	}

	componentDidMount(){
		this.fetchData();
	}

	componentWillReceiveProps(nextProps) {
    	this.props = nextProps;
    	this.setState({
			searchTerm: '',
    		searchResults: []
    	})
	}

	setTags(result){
		this.setState({
			tags: result,
			isLoaded: true,
			currentIndex: 0
		});
	}

	fetchData(){
		if (localStorage.hasOwnProperty("alltags")) {
			let data = JSON.parse( localStorage.getItem("alltags") );
			//console.log("From Localstorage", data);
			this.setTags( data );
		}else{
			//console.log("From Server");
			this.fetchDataFromServer();
		}
	}

	fetchDataFromServer(){
		tagService.getAllTags()
			.then(response => {
				this.setTags(response);
	            // Save Tags to Local Storage:
	            localStorage.setItem("alltags", JSON.stringify(response));
			})
			.catch(error => {
				console.log("Get all Tags error!", error);
                this.setState({
                    isLoaded: true,
                    error
                })
			})
	}

	handleChange(event){
		let filteredResults = [];
		this.setState({searchTerm: event.target.value});
		const {searchTerm} = this.state;

		if (searchTerm.length > 0){
			filteredResults = this.state.tags.filter(p => 
		       //p.name.toLowerCase().includes(search.toLowerCase())
		       p.name.toLowerCase().startsWith(searchTerm.toLowerCase())
		    )
		}

		this.setState({ searchResults: filteredResults });
	}

	handlePress(event){

		if (event.key === "ArrowUp" || event.key === "ArrowDown"){
			this.handleArows(event.key);
		}else if (event.key === "Enter"){
			this.handleEnter();
		}

	}

	// Up and Down arrows
	handleArows(event){

		let newIndex = this.state.currentIndex;
		if (event === "ArrowUp"){
			newIndex--;
		}else{
			newIndex++;
		}

		// If less than zero:
		if (newIndex < 0){ newIndex = 0; }
		// if greater than search results:
		if (newIndex > this.state.searchResults.length){ 
			newIndex = this.state.searchResults.length;
		}

		this.setState({ currentIndex: newIndex });
	}

	handleEnter(){
		if (this.state.currentIndex !== 0 && this.state.searchResults.length !== 0){
			// Pass Event to TagRoute Parent:
			this.props.handleEnterSearch(
				this.state.searchResults[this.state.currentIndex-1].slug
			);

			// Clear the Search:
			this.setState({
				searchResults: [],
				currentIndex: 0
			});
		}else if (this.state.searchResults.length !== 0){
			// SearchResults exist but the index does not.
			// select the first item
			this.props.handleEnterSearch(
				this.state.searchResults[0].slug
			);
		}
	}

	render(){
		return(
			<SearchBar 
				handleChange={this.handleChange}
				handlePress={this.handlePress}
				searchTerm={this.state.searchTerm}
				searchResults={this.state.searchResults}
				currentIndex={this.state.currentIndex} />
		)
	}

}