import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { PhotoDefault } from './PhotoDefault';
import { PhotoPortrait } from './PhotoPortrait';
import { PhotoFullscreen } from './PhotoFullscreen';
import {Video} from './Video';

export class PhotoContainer extends Component {

	constructor(props){
		super(props);
		this.photoTaken = this.photoTaken.bind(this);

		this.myRef = React.createRef();		
	}

	componentDidMount(){
		if (this.props.isAnchor){
			const testNode = ReactDOM.findDOMNode(this.myRef.current);
			testNode.scrollIntoView();
		}
	}

	photoTaken(){
		if (this.props.content.dateTaken.raw === "0000-00-00"){
			return false;
		}
		if (this.props.content.date !== this.props.content.dateTaken.raw){
			return this.props.content.dateTaken.formatted;
		}
		return false;
	}

	render(){

		const {content, photoNumber} = this.props;
		if (content.type === "VIDEO"){
			return (
				<Video 
					content={content}
					photoTaken={this.photoTaken()}
					ref={this.myRef} />
			)
		}

		else if (content.size.width <= 500){
			const isEven = (photoNumber % 2 === 0) ? true : false;
			return(
				<PhotoPortrait
					content={content}
					photoTaken={this.photoTaken()}
					alternate={isEven}
					ref={this.myRef} />
			)
		}

		else if (content.size.width > 1000){
			return(
				<PhotoFullscreen
					content={content}
					photoTaken={this.photoTaken()}
					ref={this.myRef} />
			)
		}

		else{
			return(
				<PhotoDefault 
					content={content}
					photoTaken={this.photoTaken()}
					ref={this.myRef} />
			);
		}
	}

}
