import React, { Component } from 'react';
import {Helmet} from "react-helmet";

import Loading from '../shared/Loading';
import Error from '../shared/Error';

import { Map } from './Map';

import mapService from '../../services/map';

export class MapRoute extends Component {

	constructor(props){
		super(props);

		this.state = {
			isLoaded: false,
			featureCollection: []
		}

		this.setMapData = this.setMapData.bind(this);
	}
	componentDidMount() {
		this.fetchData();
	}

	setMapData(results){
		this.setState({
			isLoaded: true,
			featureCollection: results
		});
	}

	fetchData(){

		if (localStorage.hasOwnProperty("map")) {
			//console.log("fetching map data from local storage");	
			let result = JSON.parse( localStorage.getItem("map") );
			this.setMapData(result);
		}else{
			//console.log("fetching map data from server");
			this.fetchDataFromServer();
		}

	}

	fetchDataFromServer(){

		mapService.getMapPhotos()
			.then(response =>{
				this.setMapData(response);
				//Save Data to LocalStorage
				localStorage.setItem("map", JSON.stringify(response));
			})
			.catch(error =>{
				console.log("Error!", error);
                this.setState({
                    isLoaded: true,
                    error
                })
			})

	}

	render(){
		if (this.state.error){
			return(
				<Error reason="Error loading map data." />
			)
		}
		else if (this.state.isLoaded){
			return(
				<div className="mapcontainer">
					<Helmet>
						<title>Map - Jesse.Photo</title>
					</Helmet>

					<Map 
						featureCollection={this.state.featureCollection}
						selectedPhoto={this.props.match.params.id}
					/>

				</div>
			)
		}else{
			return(
				<Loading />
			)
		}

	}

}