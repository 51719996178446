import React from 'react';
import Equipment from './Equipment';

import PhotoUtils from '../../../shared/PhotoUtils';

const EquipmentLoop = ({equipment, id, photoTaken}) => {
	
	return(
		<ul className="meta menu vertical">
			{equipment.map((singleEquipment, i) => {
				return(
					<Equipment
						equipmentName={singleEquipment.name}
						key={singleEquipment.id} />
				)
			})}

			{!!photoTaken && 
				<li>Taken on <em>{photoTaken}</em></li> }
				
		    <li>Photo #{id}</li>
			
			{!! (!PhotoUtils.isProduction()) &&
				<li>
					<a href={`https://old.jesse.photo/admin/content/${id}`} 
						target="_blank"
						rel="noopener noreferrer"
						className="edit-link">Edit Photo</a>
				</li>
			}
		</ul>
	)

}

export default EquipmentLoop;
