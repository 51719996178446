import React from 'react';
import PhotoUtils from '../shared/PhotoUtils';

const Marker = ({point}) => {

    const thumbnail = PhotoUtils.getThumbnailFilePath(point.date, point.file);
    const friendlyDate = PhotoUtils.formatFriendlyDate(point.date);
    
    return(
        <div className="marker">
            <a href={`/${point.date}/${point.id}`}>
                <img src={thumbnail} alt="Marker Thumbnail" />
                {!! point.title && <div className="title">{point.title}</div>}
                <div className="date">{friendlyDate}</div>
            </a>
        </div>
    )
}

export default Marker;