import buildData from '../../data/buildData';

const getYear = (date) =>{
    if (!isDate(date)){ return false; }
    return date.split("-")[0];
}

const getMonth = (date) => {
    if (!isDate(date)){ return false; }
    return date.split("-")[1];
}

const isDate = (date) => {
    if (date === undefined || date === null){
        return false;
    }

    const dateArray = date.split("-");

    if (dateArray.length !== 3){
        return false;
    }

    if (
        !isNaN(Number.parseInt(dateArray[0])) &&
        !isNaN(Number.parseInt(dateArray[1])) &&
        !isNaN(Number.parseInt(dateArray[2]))
        )
    {
        return true;
    }
    return false;
}

const calendarLinkFromDate = (date) =>{
    if (!isDate(date)){ return "/calendar"; }

    const dateArray = date.split("-");
    return `/calendar/${dateArray[0]}/${dateArray[1]}/`;
}

const isDateInTheFuture = (date) =>{
    const theDate = new Date(date).getTime();
    const now = Date.now();

    return (theDate >= now);
}

const returnMonthString = (month) => {
    return ('0' + (month)).slice(-2);;
}

const returnMonthFromString = (month) => {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return months[month-1];
}

const returnToday = () => {
    return new Date().toJSON().slice(0,10);
}

const isDateInMonth = (date, year, month) => {
    const dateArray = date.split('-');

    if (dateArray[0] === year && dateArray[1] === month){
        return true;
    }
    return false;
}

const getNextMonth = (year, month) =>{

    let nextMonth = parseInt(month)+1;
    let nextYear = year;

    if (nextMonth > 12){
        nextMonth = "01";
        nextYear = String( parseInt(year)+1 );
    }

    let text = `${returnMonthFromString(nextMonth)} ${nextYear}`;


    return {
        month: returnMonthString(nextMonth),
        year: nextYear,
        text: text
    }
}

const getPreviousMonth = (year, month) => {
    let previousMonth = parseInt(month-1);
    let previousYear = year;

    if (previousMonth < 1){
        previousMonth = "12";
        previousYear = String( parseInt(previousYear)-1 );
    }

    let text = `${returnMonthFromString(previousMonth)} ${previousYear}`;

    return {
        month: returnMonthString(previousMonth),
        year: previousYear,
        text: text
    }

}

const isDateInScope = (_year, _month) =>{

    // convert to ints if needed:
    const year = parseInt(_year);
    const month = parseInt(_month);

    // Check the past:
    if (year === 2006 && month < 8){
        return false;
    }

    // Check the future:
    var today = new Date();
    
    // check if the year is in the future
    if (year > today.getFullYear()){
        return false;
    }

    // check if current year, and the month
    if (year === today.getFullYear() && month > today.getMonth()+1){
        return false;
    }
    
    // valid month/year
    return true;
}

const formatFriendlyDate = (date) =>{
    const splitDate = date.split("-");
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    
    let suffix;

    switch ( parseInt(splitDate[2]) ) {
        case 1: case 21: case 31: suffix = "st"; break;
        case 2: case 22:          suffix = "nd"; break;
        case 3: case 23:          suffix = "rd"; break;
        default:                  suffix = "th"; break;
    }


    return `${months[parseInt(splitDate[1])-1]} ${parseInt(splitDate[2])}${suffix}, ${splitDate[0]}`;
}

const getThumbnailFilePath = (date, filename) =>{
    // If there is no filename, return the video play button:
    if(!filename){
        return "https://images.jesse.photo/site/play-button.png";
    }

    const year = getYear(date);
    return `https://images.jesse.photo/${year}/${date}/thumb-${filename}`;
}

const tagSlugToTagName = (slug) => {
    if (!slug){ return '' }
    return slug.replace(/_/g, ' ');
}

const isProduction = () =>{
    if (buildData.env === "Production"){
        return true;
    }
    return false;
}

export default { 
    getYear, 
    getMonth,
    isDate, 
    returnMonthString, 
    calendarLinkFromDate, 
    isDateInMonth,
    returnToday,
    returnMonthFromString,
    getThumbnailFilePath, 
    tagSlugToTagName, 
    isDateInTheFuture,
    formatFriendlyDate,
    isProduction,
    getNextMonth,
    getPreviousMonth,
    isDateInScope
}