import React from 'react';
import {Helmet} from "react-helmet";

import PhotoUtils from '../../shared/PhotoUtils';

import PhotoThumbnail from '../../shared/PhotoThumbnail';

const SearchResults = ({tagName, totalPhotos, collection, currentPage, numberOfPages}) => {

	return(
		<div className="tag-result major-section">
			<Helmet>
				<title>{ ` ${tagName} - Tags - Jesse.Photo` }</title>            
			</Helmet>
			<h1 className="text-center">
				Tag: 
				<em> {tagName}</em>
			</h1>

			<p>
				{(numberOfPages !== 1) &&
					<>Page {currentPage} of {numberOfPages} with </>
				}
				{totalPhotos} photos
			</p>

			<div className="grid-x thumbnail-container">
				{collection.map((singlePhoto, i) => {  
					return(
						<PhotoThumbnail
							key={singlePhoto.id}
							url={`/${singlePhoto.date}/${singlePhoto.id}`}
							image={PhotoUtils.getThumbnailFilePath(singlePhoto.date, singlePhoto.file)}
						/>							
					);
				})}
			</div>
		</div>
	)
}

export default SearchResults;