import React from 'react';
import { Link } from 'react-router-dom';

const TagsSvg = ({count}) =>{
return(
    <Link to="/tag">
        <svg version="1.1" id="tags" x="0px" y="0px" viewBox="0 0 300 300">
            <g>
                <path className="st0" d="M149.5,224.5c-40.1,0-80.2,0-120.3,0c-6.1,0-5.8-0.1-7.4-5.8c-2.3-8.3-7.8-13.4-16.3-15.4
                    c-1.8-0.4-3.9-1.4-4.8-2.8c-1.1-1.7-0.5-3.8,1.7-5.2c2.9-1.9,2.9-4.2,0.4-6.5c-3-2.8-2.9-5.1,0.1-7.8c2.2-2,2.2-3.8,0-5.9
                    c-3.6-3.5-3.6-4.8,0.3-8.1c2.3-2,2.1-4.1-0.5-6c-3.1-2.3-3.2-4.9-0.2-7.3c2.6-2.1,2.7-4.2,0.2-6.5c-2.7-2.4-2.7-4.9,0-7.3
                    c2.7-2.5,2.7-3.9-0.1-6.6c-3.1-2.9-3-4.9,0.3-7.6c2.7-2.2,2.7-4.1-0.1-6.1c-3.2-2.4-3.2-4.9,0-7.4c2.5-2,2.2-4.1,0.1-6.2
                    c-1.3-1.4-2.1-3.7-2.2-5.6c-0.1-2.4,2.3-1.8,3.9-2.2c9.5-2.9,16.2-8.7,18.2-18.8c0.6-2.8,1.5-4.3,4.6-3.4c1.1,0.3,2.4,0.1,3.5,0.1
                    c79.3,0,158.7,0,238,0c0.4,0,0.9,0,1.3,0c6.1,0,6.3-0.1,8,5.9c2.1,7.5,6.6,12.5,14.1,14.7c1.8,0.5,3.6,1.1,5.2,2.1
                    c1,0.6,2.3,1.9,2.2,2.7c-0.3,1.6-1,3.6-2.2,4.6c-2.5,2.2-2.7,3.9-0.2,6.1c3.3,3,3.4,4,0.3,7.3c-2.8,3.1-2.8,4.7,0.4,7.4
                    c2.2,1.8,2.3,3.9,0.3,6.1c-3.7,4-3.7,4.4,0.2,7.7c1.9,1.6,1.9,3.5,0.2,5.3c-4,4.3-3.9,4.3,0.1,8.9c0.7,0.9,0.3,2.8,0.3,4.2
                    c0,0.4-0.4,0.7-0.7,1.1c-3.7,4.7-3.6,4.6,0.7,8.7c2.2,2.1,0.4,4.1-1,5.6c-3.3,3.7-3.3,4.5,0.4,7.6c1.9,1.6,1.8,3.4,0,5.5
                    c-1.2,1.4-2.5,3-2.7,4.6c-0.2,1,1.7,2.2,2.4,3.5c0.8,1.5,1.7,3.3,1.6,4.9c-0.1,0.9-1.9,1.9-3.2,2.5c-3.5,1.5-7.7,2.1-10.6,4.4
                    c-3.8,3-6.7,7-7.8,12.1c-1.2,5.5-1.4,5.4-7.1,5.4C230.3,224.5,189.9,224.5,149.5,224.5z"/>
            </g>
            <g>
                <path className="st1" d="M254,213.5H46c-9.3,0-17-7.7-17-17v-93c0-9.3,7.6-17,17-17h208c9.4,0,17,7.7,17,17v93
                    C271,205.8,263.4,213.5,254,213.5z"/>
                <line className="st1" x1="29.7" y1="116.1" x2="270.7" y2="116.1"/>
                <line className="st1" x1="270.3" y1="186.9" x2="29.3" y2="186.9"/>
            </g>
            <text transform="matrix(1 0 0 1 100.7173 173.7979)" className="st2 st3 st4">{count}</text>
            <text transform="matrix(1 0 0 1 139.3923 203.7656)" className="st2 st3 st5">tags</text>
            <g>
                <polygon className="st2 star" points="130,92.1 132.2,98.7 139.2,98.7 133.5,102.8 135.7,109.5 130,105.4 124.3,109.5 126.5,102.8 
                    120.8,98.7 127.8,98.7"/>
                <polygon className="st2 star" points="150,92.1 152.2,98.7 159.2,98.7 153.5,102.8 155.7,109.5 150,105.4 144.3,109.5 146.5,102.8 
                    140.8,98.7 147.8,98.7"/>
                <polygon className="st2 star" points="170,92.1 172.2,98.7 179.2,98.7 173.5,102.8 175.7,109.5 170,105.4 164.3,109.5 166.5,102.8 
                    160.8,98.7 167.8,98.7"/>
            </g>
        </svg>
    </Link>
    )
}

export default TagsSvg;