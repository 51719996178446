import React, { useState, useEffect } from 'react'

const PasswordScreen = React.Component = ({ authenticated }) => {
  const [input, setInput] = useState('')
  const [error, setError] = useState(null)

  const handleLogin = (evt) => {
    if (evt) {
      evt.preventDefault()
    }

    setError(null)
    const msgBuffer = new TextEncoder().encode(input)

    // reference: https://stackoverflow.com/questions/18338890/are-there-any-sha-256-javascript-implementations-that-are-generally-considered-t
    crypto.subtle.digest('SHA-256', msgBuffer).then(value => {
      const hashArray = Array.from(new Uint8Array(value))
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
      checkHashedPassword(hashHex)
    }).catch(err => {
      console.log(err)
      setError('Your browser may not support this encryption method. Please try using a different browser such as Chrome.')
    })
  }

  const checkHashedPassword = (hashHex) =>
    (hashHex === '811eb81b9d11d65a36c53c3ebdb738ee303403cb79d781ccf4b40764e0a9d12a')
        ? authenticated() : setError('incorrect')

  useEffect(() => {
    if (localStorage.getItem('PASSWORD')) {
      console.log('checking localstorage')
      checkHashedPassword(localStorage.getItem('PASSWORD'))
    }
  }, [])

  return(
    <div className="grid-container major-section password">
      <div className="grid-x">
        <div className="cell medium-4 medium-offset-4 content">
          <h1>Please log in</h1>
          <form onSubmit={handleLogin}>
            <input
              type="text"
              value={input}
              onChange={evt => setInput(evt.target.value)}
              className="password"
              placeholder="password..."  
            />
            <button
              onClick={handleLogin}
              className="button"
            >Log in</button>
          </form>

          {error && <div className="alert error">{error}</div>}
        </div>
      </div>
    </div>
  )
}

export default PasswordScreen
