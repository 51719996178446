import React, { Component } from 'react';
import { TopTags } from './TopTags';
import Loading from '../../shared/Loading';
import Error from '../../shared/Error';

import tagService from '../../../services/tag';

export class TopTagsContainer extends Component {

	constructor(props){
		super(props);
		this.state = {
			tags: [],
			isLoaded: false
		}

		this.setTags = this.setTags.bind(this);
	}

	componentWillMount(){
		// Because it uses local storage and fetching:
		this.fetchTopTags();
	}

	setTags(result){
		this.setState({
			tags: result,
			isLoaded: true
		});
	}

	// determin where to get the 
	fetchTopTags(){
		if (localStorage.hasOwnProperty("toptags")) {			
			let data = JSON.parse( localStorage.getItem("toptags") );
			this.setTags( data );
		}else{
			this.fetchTopTagsFromServer();
		}
	}

	fetchTopTagsFromServer(){
		tagService.getTopTags(30)
			.then(response =>{
				this.setTags(response);
				localStorage.setItem("toptags", JSON.stringify(response));
			})
			.catch(error =>{
				console.log("Get top tags Error", error);
                this.setState({
                    isLoaded: true,
                    error
                })
			})
	}

	splitTags(){
		var columns = [
			this.state.tags.slice(0,10),
			this.state.tags.slice(10,20),
			this.state.tags.slice(20,30),
		];
		return columns;
	}

	render(){
		if (this.state.error){
			return(
				<Error reason="Error fetching top tags." />
			)
		}
		else if (this.state.isLoaded){
			return(
				<TopTags tags={this.splitTags()} />
			);
		}else{
			return(
				<Loading />
			)
		}

	}


}