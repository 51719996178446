import React from 'react';

const Loading = () => {
	return(
		<div className="loading text-center major-section">
			<img src="/images/loading.svg" alt="Loading Page" />
		</div>
	)
}

export default Loading;