import React, { Component } from 'react';

import TagLoop from './tag/TagLoop';
import EquipmentLoop from './equipment/EquipmentLoop';
import MapLink from './mapLink/MapLink';

export class Video extends Component{

	render(){
        const {content, photoTaken} = this.props;
        
		return(
            <div className="photo major-section grid-container">
                
                <div className="cell">
                    {!!content.title && <h2>{content.title}</h2>}

                    <div className="description"
                dangerouslySetInnerHTML={{__html: content.description}}></div>
                </div>

                <div className="grid-x major-section">
                    <div className="cell medium-3 medium-offset-1">
                        <TagLoop tags={content.tags} />
                    </div>

                    <div className="cell medium-3">
                        <EquipmentLoop
                            equipment={content.equipment}
                            photoTaken={photoTaken}
                            id={content.id} />
                    </div>

                    <div className="cell medium-4">
                        <MapLink lat={content.location.lat}
                        lng={content.location.lng}
                        id={content.id} />
                    </div>
                </div>

            </div>
        );
    }
}
