import React from 'react';

const Error = ({reason}) => {

    localStorage.clear("");
    
    return(
        <div className="error major-section">
            <h1>Oops, something happened.</h1>
            <img src="/images/error-bug.svg" alt="Bug Error" />
            <p>{reason}</p>
            <p>Please try refreshing this page, or click back <a href="/">Home</a>.</p>
        </div>
    )
}

export default Error;