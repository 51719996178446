import React, { Component } from 'react';
import {Footer} from './Footer';

import buildData from '../../data/buildData';

export class FooterContainer extends Component {

	render(){
		const commit = buildData.commit.substr(0, 7);
		return(
			<Footer
			pipeline={buildData.pipeline}
			dateUpdated={buildData.date}
			env={buildData.env}
			commit={commit} />
		)
	}

}