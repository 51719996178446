import React from 'react';
import { Link } from 'react-router-dom';

const Tag = ({tagName, slug}) => {
	return (
		<li>
			<Link to={`/tag/${slug}`}>
				<span className="label secondary">
					{tagName}
				</span>
			</Link>
		</li>
	)
}

export default Tag;