import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import PhotoUtils from '../shared/PhotoUtils';

export class Navigation extends Component {

	handleClick(event){
		var menu = document.getElementById("main-menu");
		var isClosed = (menu.classList[1] ? '' : ' closed');
		menu.className= "menu" + isClosed;
	}

	closeMenu(event){
		document.getElementById("main-menu").className = "menu closed";
	}

	render(){

		const isProd = (PhotoUtils.isProduction()) ? '' : 'testing';

		return (
			<div className="top-bar" id="top-bar">
			    <div className="top-bar-left">
			      <ul className="menu">
			        <li className="show-for-small-only">
			        	<button className="menu-icon"
			        			onClick={this.handleClick} />
			        </li>
			        <li className="menu-text" role="menuitem">
			        	<Link to="/">Jesse Clark <span className={isProd}>photo of the day</span>
			        	</Link>
			        </li>			        
			      </ul>
			    </div>
			    <div className="top-bar-right">
			      <ul className="menu closed" id="main-menu" onClick={this.closeMenu} >
			        <li><Link to='/'>today</Link></li>
			        <li><Link to='/random'>random</Link></li>
					<li><Link to='/calendar'>calendar</Link></li>
					<li><Link to='/map'>map</Link></li>
					<li><Link to='/tag'>tags</Link></li>
					<li><Link to='/about'>about</Link></li>
			      </ul>
			    </div>
			</div>
		)

	}

}