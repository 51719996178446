import React from 'react';

const CameraSvg = ({count}) =>{
    return (
        <div id="camera">
            <svg version="1.1" x="0px" y="0px" viewBox="0 0 300 300">
                <g>
                    <path d="M114.6,273.1c-3.4,0-6.8,0-10.1,0c-22.6,0-45.2,0-67.7,0C16,273.2,2,259.4,1.9,238.9c0-42.4,0-84.7,0-127.1
                        C2,91.2,16,77.3,36.6,77.3c6.8,0,13.5,0,20.3,0c10.4,0,18.2-4.4,23.9-13.1c1.5-2.2,4.8-4.4,7.4-4.4c41.2-0.3,82.4-0.3,123.6-0.1
                        c2.3,0,5.5,2,6.8,4.1c5.7,9.1,13.7,13.6,24.4,13.5c7.2-0.1,14.4-0.1,21.7,0c18.3,0.3,33.1,14.4,33.2,32.7
                        c0.3,43.5,0.2,87.1,0,130.6c-0.1,16.3-13.2,31.7-28.4,32.1c-28.4,0.7-56.7,0.2-85.4-0.2c26-10.6,45.9-27.8,58-53.2
                        c12.2-25.6,13.4-51.9,3.9-78.6c-15.3-42.8-59.1-71.3-103.4-67.5c-46.4,4-84.9,38.1-93.1,82.1C40.7,201.9,63.2,251.1,114.6,273.1z"
                        />
                    <path d="M149.4,271.5C97.3,271.7,53.9,229,53.6,177c-0.3-54.4,41.8-97.6,95.9-98.2c52.5-0.6,96.3,43,96.8,96.3
                        C246.8,228.1,203.5,271.2,149.4,271.5z M231.5,175.7c0.1-45.6-36.3-82.2-81.7-82.2c-45.1,0-81.8,36.6-82,81.5
                        c-0.2,45.1,36.9,82.3,82,82.4C194.8,257.4,231.4,220.7,231.5,175.7z"/>
                    <path d="M85.5,53.1c4.1-14.5,18.4-25.4,34.8-25.8c20-0.5,40-0.5,59.9,0c16,0.4,30.4,11.8,33.7,25.8
                        C171.2,53.1,128.6,53.1,85.5,53.1z"/>
                    <path d="M61.5,74.2c-10.5,0-18.3,0-28.1,0c1.4-4.3,1.9-10.6,3.8-11c6.2-1.4,13.1-1.6,19.3-0.3C58.5,63.3,59.5,69.4,61.5,74.2z"/>
                    <circle cx="151.2" cy="177.3" r="65.2"/>
                    <text transform="matrix(1 0 0 1 101.7861 203.3571)" className="st0 st1 st2">{count}</text>
                </g>
            </svg>
            <p>cameras</p>
        </div>
    )
}

export default CameraSvg;