import React from 'react';
import { Link } from 'react-router-dom';

const MapLink = ({id, lat, lng}) => {
	const apiKey = "pk.eyJ1IjoiamVzc2d1c2NsYXJrIiwiYSI6ImNqd29xdDVwNjEybGI0OXFtOWVka2NycWwifQ.7eDQFHFpwyDSr3JwngmCEA";

	if (lat !== "0" && lng !== "0"){
		const location = lng + "," + lat;
		return(
			<>
				<Link to={`/map/${id}`} className="photoMap">
					<img src={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/url-https%3A%2F%2Fjesse.photo%2Fimages%2Fmap%2Fmarker-icon.png(${location})/${location},12/300x100?access_token=${apiKey}`}
						alt="See this on the map" />
				</Link>
				<p className="location">{lat},{lng}</p>
			</>
		)
	}else{
		return("");
	}

}

export default MapLink;