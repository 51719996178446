import React, { Component } from 'react';

import TagLoop from './tag/TagLoop';
import EquipmentLoop from './equipment/EquipmentLoop';
import MapLink from './mapLink/MapLink';

export class PhotoDefault extends Component{

	render(){

		const {content, photoTaken} = this.props;

		const isMap = (content.location.lat !== "0" && content.location.lng !== "0") ? true : false;
		const tagWidth = isMap ? "3" : "5";
		const equipmentWidth = isMap ? "3" : "6";

		return(
			<div className="photo major-section grid-container">

				<img src={content.filename}
						alt={content.title}
						width={content.size.width}
						height={content.size.height} />

				<div className="grid-x major-section">
					<div className="cell small-12 large-offset-1 large-10">
						{!!content.title && <h2>{content.title}</h2>}

						<div className="description"
					dangerouslySetInnerHTML={{__html: content.description}}></div>
					</div>
				</div>

				<div className="grid-x major-section">
					<div className={`cell medium-${tagWidth} medium-offset-1`}>
						<TagLoop tags={content.tags} />
					</div>

					<div className={`cell medium-${equipmentWidth}`}>
						<EquipmentLoop
							equipment={content.equipment}
							photoTaken={photoTaken}
							id={content.id} />
				    </div>
					{isMap &&
						<div className="cell medium-4">
							<MapLink lat={content.location.lat}
							lng={content.location.lng}
							id={content.id} />
						</div>
					}

				</div>

		        

			</div>
		);
	}

}
