import React, { Component } from 'react';

import TagLoop from './tag/TagLoop';
import EquipmentLoop from './equipment/EquipmentLoop';
import MapLink from './mapLink/MapLink';

export class PhotoFullscreen extends Component{

	render(){
		return(
			<div className="photo major-section">
				<img src={this.props.content.filename}
					 alt={this.props.content.title} 
					 className="widescreen" />

				<div className="grid-x major-section">
					<div className="cell small-10 small-offset-1">
						<div className="grid-x major-section">
								<div className="cell small-12 large-offset-1 large-10">
									{!!this.props.content.title && <h2>{this.props.content.title}</h2>}

									<div className="description"
								dangerouslySetInnerHTML={{__html: this.props.content.description}}></div>
								</div>
							</div>

							<div className="grid-x major-section">
								<div className="cell medium-3 medium-offset-1">
									<TagLoop tags={this.props.content.tags} />
								</div>

								<div className="cell medium-3">
									<EquipmentLoop 
										equipment={this.props.content.equipment}
										photoTaken={this.props.photoTaken}
										id={this.props.content.id} />
							    </div>

							    <div className="cell medium-4">
							    	<MapLink lat={this.props.content.location.lat}
					        		 lng={this.props.content.location.lng}
					        		 id={this.props.content.id} />
					        	</div>
							</div>
					</div>
				</div>
			</div>
		)
	}

}