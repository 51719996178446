import React, { Component } from 'react';
import { Tag } from './Tag';

export class TagColumn extends Component {

	render(){

		return(
			<div className="cell medium-4">
				<ul className="menu vertical">
					{this.props.tags.map((singleTag, i) => {     
			            return(
				           	<Tag tagName={singleTag.name}
				           		 slug={singleTag.slug}
				           		 key={singleTag.id} />
				           	)  
			        })}
		        </ul>
	        </div>
        )
    } 
}