import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { KeyboardSingletonClass } from '../../shared/KeyboardShortcuts';
import PhotoUtils from '../../shared/PhotoUtils';

class NextPrevious extends Component {

	constructor(props){
		super(props);
		const keys = KeyboardSingletonClass.getInstance();
		if (this.props.previous){
			keys.addShortcut("ArrowLeft", "/" + this.props.previous);
		}
		if (this.props.next){
			keys.addShortcut("ArrowRight", "/" + this.props.next);
		}
	}

	// Component is unmounting
	componentWillUnmount(){
		const keys = KeyboardSingletonClass.getInstance();
		keys.removeShortcut("ArrowLeft");
		keys.removeShortcut("ArrowRight");
	}

	render(){

		const {next,previous,date} = this.props;
		const month = PhotoUtils.getMonth(date);
		const year = PhotoUtils.getYear(date);

		return(
			<div className="grid-x grid-margin-x next-previous">
				<div className="cell small-6 medium-4 medium-offset-2">
					{!!previous && 
						<p className="text-right"><Link 
							to={"/" + previous}
							date={previous}>
						Previous
						</Link></p>
					}
				</div>
				<div className="cell small-6 medium-4">
					{!!next && 
						<p><Link to={"/" + next}>
							Next
						</Link></p>
					}
				</div>
				{ !PhotoUtils.isDateInMonth(PhotoUtils.returnToday(), year, month) &&
					<div className="cell small-12 medium-2">
						<p><Link to={PhotoUtils.calendarLinkFromDate(date)}>
							{PhotoUtils.returnMonthFromString(month)} {PhotoUtils.getYear(date)}
						</Link></p>
					</div>
				}
			</div>
		)

	}

}

export default NextPrevious;